<template>
  <section class="mt-3">
    <b-overlay :show="loading" rounded="sm">

      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("NAME") }}</h5>
      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.name"
          name="name"
        ></b-form-input>
      </div>

      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("TITLE") }}</h5>

      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.title"
          name="title"
        ></b-form-input>
      </div>

      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("DEPARTMENT") }}</h5>
      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.department"
          name="name"
        ></b-form-input>
      </div>

      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("ADMINLEVEL.TITLE") }}</h5>
      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-select
          v-model="userinfo.is_admin"
          :options="adminOptions"
        ></b-form-select>
      </div>

      <h5 class="mt-3"  v-if="user.information.is_admin > 0">{{ $t("LANGUAGE.TITLE") }}</h5>
      <div class="mt-1" v-if="user.information.is_admin > 0">
        <b-form-select  name="edit_user_select_language" v-model="userinfo.locale" class="mb-3" >
          <b-form-select-option v-for="(value, key) in sefos_locales" :key="key" :value="key">{{value}}</b-form-select-option>
        </b-form-select>
</div>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>

      <b-button
        v-if="userinfo.id != user.id"
        variant="danger"
        class="btn-fill float-right"
        @click="removeSubmit"
        >{{ $t("REMOVE") }}</b-button
      >
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      loading: false
    };
  },
  methods: {
    saveSubmit: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/user/edit", this.userinfo)
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedUser", result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    },
    removeSubmit: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {      
          self.loading = true;
          self.$http
            .post(self.user.hostname + "/user/remove", self.userinfo)
            .then(function() {
              self.$emit("removedUser");
              self.$noty.info(self.$t("REMOVED"));
            })
            .catch(function() {
              self.loading = false;
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {
    adminOptions: function() {
      if (this.user.information.is_admin == 2) {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
          { value: 2, text: this.$t("ADMINLEVEL.2") },
        ];
      } else {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
        ];
      }
    },
  },
  mounted: function() {},
};
</script>
<style></style>
